<template>
  <news-detail :is-edit="true" />
</template>

<script>
import NewsDetail from "./components/newsDetail";

export default {
  name: "EditForm",
  components: { NewsDetail },
};
</script>
